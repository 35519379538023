import Footer from "../Components/Footer";
import {NavBar} from "antd-mobile";
import React from "react";

export function Home() {
    return <div>
        <NavBar backArrow={false}>
            Главная
        </NavBar>


        <Footer/>
    </div>
}