import { useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react';

export default function Root () {
    const navigate = useNavigate()

    useEffect(() => {
        navigate('/appointments')
    });

    return <div/>
}