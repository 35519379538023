import * as React from "react";
import {Route, Routes} from "react-router-dom";
import "./App.css";
import Login from "./Login";
import Home from "./Home";
import Appointments from "./Appointments";
import AppointmentCreate from "./AppointmentCreate";
import Account from "./Account";
import Root from "./Root";
import NotFound from "./NotFound";
import axios from "axios";
import { Toast } from 'antd-mobile'
import {Validate} from "./Validate/Validate";


axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        Toast.show({
            icon: 'fail',
            content: 'Вы не авторизованы',
        })

        setTimeout(() => {window.location = '/login'}, 2000)

    } else if (403 === error.response.status) {
        console.log(error.response.data.detail)

        if (error.response && error.response.data && error.response.data.detail && error.response.data.detail === 'Not authenticated') {
            Toast.show({
                icon: 'fail',
                content: 'Вы не авторизованы',
            })

            setTimeout(() => {window.location = '/login'}, 2000)
        } else {
            Toast.show({
                icon: 'fail',
                content: 'У вас нет прав на данную операцию. Обратитесь к администратору.',
            })
        }

    } else {
        return Promise.reject(error);
    }
});

export default function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Root/>}/>
                <Route path="home" element={<Home/>}/>
                <Route path="login" element={<Login/>}/>
                <Route path="validate" element={<Validate/>}/>
                <Route path="appointments" element={<Appointments/>}/>
                <Route path="account" element={<Account/>}/>
                <Route path="create_appointment" element={<AppointmentCreate/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </div>
    );
}
