import Footer from "../Components/Footer";
import {Button, Form, Input, NavBar} from "antd-mobile";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import React, {useEffect, useState} from "react";

export function Account() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [account, setAccount] = useState({
        phone: null,
        firstname: null,
        lastname: null
    });

    function getUserAccount() {
        return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/users/me`, {headers: {'api': localStorage.getItem('token')}})
            .then(function (response) {
                setAccount(response.data)
            })
            .catch(function (error) {

            })
            .then(function () {
                setLoading(false);
            });
    }

    // function handleSubmitForm() {
    //
    // }

    function handleLogout() {
        localStorage.removeItem('token')
        navigate('/login')
    }

    useEffect(() => {
        getUserAccount();
    }, [])


    return <div>
        <NavBar back='Назад' onBack={() => navigate(-1)}>
            Профиль
        </NavBar>

        {loading ? '' : <div>
            <Form
                layout='horizontal'
                // onFinish={handleSubmitForm}
                // footer={
                //     <Button block type='submit' color='primary'>
                //         Получить смс-код
                //     </Button>
                // }
            >
                <Form.Item name='phone' label='Телефон'>
                    <Input label='Телефон' name='phone' defaultValue={account.phone} readOnly />
                </Form.Item>

                <Form.Item name='lastname' label='Фамилия'>
                    <Input label='Фамилия' name='lastname' defaultValue={account.lastname} readOnly />
                </Form.Item>

                <Form.Item name='firstname' label='Имя'>
                    <Input label='Имя' name='firstname' defaultValue={account.firstname} readOnly />
                </Form.Item>


            </Form>

        </div>}

        <Button color='warning' fill='outline' block onClick={handleLogout}>Выйти</Button>

        <Footer/>
    </div>
}