import Footer from "../Components/Footer";
import {Button, DatePicker, Image, List, NavBar, Result, Tag, Collapse, TextArea, Form, Toast} from "antd-mobile";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import moment from "moment";
import './AppointmentCreate.css'

export function AppointmentCreate() {
    const [loading, setLoading] = useState(true);
    const [pickerVisible, setPickerVisible] = useState(false);
    const [selectedDay, setSelectedDay] = useState(new Date());
    const [selectedTimeslot, setSelectedTimeslot] = useState();
    const [selectedEmployee, setSelectedEmployee] = useState();
    const [appointmentCreated, setAppointmentCreated] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [comment, setComment] = useState();

    function getEmployees() {
        return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/employees`)
            .then(function (response) {
                setEmployees(response.data)
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                setLoading(false)
            });
    }

    // function handleSelectTimeslot(timeslot) {
    //     setSelectedTimeslot(timeslot)
    // }


    /**
     * Создание заявки на включение в лист ожидания
     */
    function handleCreateWaiting() {

        let appointment = {
            start: `${moment(selectedDay).format('YYYY-MM-DD')} 00:00:00`,
            comment: comment,
        }
        axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/api/waiting_requests`,
            appointment,
            {headers: {'api': localStorage.getItem('token')}})
            .then(function (response) {
                Toast.show({
                    icon: 'success',
                    content: 'Заявка на включение в лист ожидания отправлена',
                })
            })
            .catch(function (error) {
            })
            .then(function () {

            })

    }

    /**
     * Создание записи
     */
    function handleCreateAppointment() {
        console.log(selectedEmployee, selectedDay, selectedTimeslot)
        let appointment = {
            start: `${moment(selectedDay).format('YYYY-MM-DD')} ${selectedTimeslot}`,
            employee_id: selectedEmployee
        }
        axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/api/user/prebooking`,
            appointment,
            {headers: {'api': localStorage.getItem('token')}})
            .then(function (response) {
                setAppointmentCreated(true)
                // console.log(response);
                // toast.success("Запись успешно создана")
                // getAppointments()
                // setShow(false)

                // notification['success']({
                //     message: "Запись успешно создана"
                // });

                // history.push({
                //     pathname: "/appointments"
                // })
            })
            .catch(function (error) {
                // console.log(error);

                // notification['error']({
                //     message: "Ошибка при создании записи"
                // });
            })
            .then(function () {

            })
    }

    useEffect(() => {
        getEmployees();
    }, [])

    const navigate = useNavigate();
    
    function renderEmployees() {
        
    }

    function appointmentPage() {
        if (appointmentCreated) {
            return <Result
                status='success'
                title='Готово'
                description={<div>
                    Заявка на запись была отправлена.
                    <br/>
                    <br/>
                    <div>
                        <Button onClick={() => navigate('/appointments')}>Перейти к записям</Button>
                    </div>
                </div>}
            />
        } else {
            return <div>
                <List
                    style={{
                        '--prefix-width': '6em',
                    }}
                >
                    <List.Item title='Дата' onClick={() => {setPickerVisible(true)}}>
                        <DatePicker
                            confirmText='Выбрать'
                            cancelText='Отменить'
                            visible={pickerVisible}
                            defaultValue={selectedDay}
                            onConfirm={(day) => {
                                setSelectedDay(day);
                                setSelectedTimeslot();
                                setSelectedEmployee();
                            }}
                            onClose={() => {
                                setPickerVisible(false)
                            }}
                            min={moment().toDate()}
                            max={moment().add(14, 'days').toDate()}
                        >
                            {value => value ? moment(value).format('YYYY-MM-DD') : 'Выберите дату'}
                        </DatePicker>
                    </List.Item>
                </List>


                {loading ? <div>Загрузка</div> : <List>
                    {employees.map(employee => {
                        if (employee.schedule.length !== 0) {
                            let working_days = employee.schedule.filter(schedule => schedule.day===moment(selectedDay).format('YYYY-MM-DD'))
                            let timeslots = []
                            if (working_days.length > 0) {
                                timeslots = working_days[0].timeslots
                            } else {
                                return false
                            }


                            return <List.Item
                                key={employee.id}
                                prefix={
                                    <Image
                                        src={`${process.env.REACT_APP_API_ENDPOINT}/files/${employee.photo_url}`}
                                        style={{ borderRadius: 60 }}
                                        fit='cover'
                                        width={60}
                                        height={60}
                                    />
                                }
                                // description="Тренер"
                            >
                                {employee.firstname} {employee.lastname}

                                <div>
                                    {
                                        timeslots.map((timeslot) => <span onClick={() => {setSelectedTimeslot(timeslot); setSelectedEmployee(employee.id)}} >
                                    <Tag className='timeslot' color={selectedTimeslot===timeslot ? 'success' : 'primary'}>{timeslot.slice(0, 5)}</Tag>
                                </span>)
                                    }
                                </div>
                            </List.Item>
                        }
                    })}
                </List>}


                <Button
                    disabled={!(selectedDay && selectedTimeslot && selectedEmployee)}
                    color='success'
                    block
                    onClick={handleCreateAppointment}
                >Записаться</Button>

            </div>
        }
    }

    return <div>
        <NavBar back='Назад' onBack={() => navigate(-1)}>
            Записаться
        </NavBar>

        {appointmentPage()}

        <br/>

        <Collapse>
            <Collapse.Panel key='1' title='Нет подходящего времени?'>
                Выберите нужный день, укажите в комментариях желаемый период времени и отправьте заявку, чтобы
                добавить заявку в лист ожидания
                <br/>
                <p>

                    <TextArea
                        placeholder='Укажите желаемый период для занятия, например, первая половина дня'
                        value={comment}
                        onChange={value => setComment(value)}
                    />

                </p>
                <Button block color='primary' size='small' onClick={handleCreateWaiting}>
                    Отправить
                </Button>

            </Collapse.Panel>
        </Collapse>

        <br/>
        <br/>
        <br/>
        <br/>

        <Footer/>
    </div>
}