import React, {useEffect, useState} from 'react';
import Footer from "../Components/Footer";
import axios from "axios";
import {List, NavBar, ActionSheet, Toast} from "antd-mobile";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import 'moment/locale/ru';
import localization from 'moment/locale/ru'


moment.locale('ru');

export function Appointments() {
    const [loading, setLoading] = useState(true);
    const [visibleActionSheet, setVisibleActionSheet] = useState(false); // Список действий
    const [selectedAppointment, setSelectedAppointment] = useState(false); // Выбранная запись
    const [appointments, setAppointments] = useState([]);
    const navigate = useNavigate();

    const actions = [
        {
            text: 'Отменить запись',
            key: 'delete',
            description: 'Отменить запись можно за день до занятия и не позднее 18:00',
            danger: true,
            disabled: !selectedAppointment.can_cancel,
            onClick: async () => {
                axios.put(`${process.env.REACT_APP_API_ENDPOINT}/api/user/appointments/${selectedAppointment.id}/cancel`,
                    {},
                    {headers: {'api': localStorage.getItem('token')}})
                    .then(function (response) {
                        getAppointments();
                        Toast.show(response.data.detail)
                    })
                    .catch(function (error) {
                        Toast.show('Ошибка при отмене записи')
                    })
                    .then(function () {
                        setLoading(false)
                    });
            }
        },
    ]

    function getAppointments() {
        return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/user/appointments`, {headers: {'api': localStorage.getItem('token')}})
            .then(function (response) {
                setAppointments(response.data)
            })
            .catch(function (error) {})
            .then(function () {
                setLoading(false)
            });
    }

    useEffect(() => {
        getAppointments();

    }, [])

    return <div>
        <NavBar back='Назад' onBack={() => navigate(-1)}>
            Записи
        </NavBar>

        {loading ? <div>Загрузка</div> : <List>
            {
                appointments.map(appointment => {
                    return <List.Item
                        key={appointment.id}
                        title=""
                        clickable
                        onClick={
                            () => {
                                setVisibleActionSheet(true);
                                setSelectedAppointment(appointment);
                            }
                        }
                        description={`Тренер ${appointment.employee.firstname} ${appointment.employee.lastname}`}
                    >
                        {`Запись на ${moment(appointment.start).locale("ru", localization).format('LLL')}`}
                    </List.Item>
                })
            }

        </List>}

        <ActionSheet
            visible={visibleActionSheet}
            actions={actions}
            onClose={() => setVisibleActionSheet(false)}
        />

        <Footer/>
    </div>
}