import { TabBar } from 'antd-mobile'
import React, { useState } from 'react'
import { useNavigate, useLocation  } from 'react-router-dom'

import {
    AppOutline,
    MessageFill,
    UnorderedListOutline,
    UserOutline,
} from 'antd-mobile-icons'

export default function Footer() {
    const navigate = useNavigate();
    const location = useLocation();

    const tabs = [
        // {
        //     key: '/home',
        //     title: 'Главная',
        //     icon: <AppOutline />,
        // },
        {
            key: '/appointments',
            title: 'Занятия',
            icon: <UnorderedListOutline />,
        },
        {
            key: '/create_appointment',
            title: 'Записаться',
            icon: <MessageFill />,
        },
        {
            key: '/account',
            title: 'Профиль',
            icon: <UserOutline />,
        },
    ]

    const [activeKey, setActiveKey] = useState(location.pathname)

    function handleChangePage (key) {
        setActiveKey(key)
        navigate(`${key}`)
    }

    return (
        <div className="footer" title="" padding="0">
            <TabBar activeKey={activeKey} onChange={(key) => handleChangePage(key)}>
                {tabs.map(item => (
                    <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                ))}
            </TabBar>
        </div>
    )
}