import {Button, Form, Input} from "antd-mobile";
import React, {useEffect, useState} from 'react';
import {useHistory, useLocation, useNavigate} from "react-router-dom";
import axios from "axios";

export function Validate() {
    const location = useLocation();
    const navigate = useNavigate();
    const [phone, setPhone] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log(location)
        setPhone(location.state.phone)
    }, [location]);

    const onFinish = (values) => {
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/token`, {
            phone: phone,
            code: values.code
        }).then(function (response) {
            setLoading(false);
            localStorage.setItem('token', response.data.access_token);
            navigate('/')


        }).catch(function (error) {
            setLoading(false);
            // setErrorMessage(error.response.data);
        });
    }

    return <>
        <div>
            <h1 className="text-center">Подтверждение</h1>
        </div>
        <div
            title='Подтвердить'
            padding='0'
            border='none'
            background='transparent'
        >
            <Form
                layout='horizontal'
                onFinish={onFinish}
                footer={
                    <Button block type='submit' color='primary'>
                        Подтвердить
                    </Button>
                }
            >
                <Form.Item
                    name='code'
                    label='Код'
                    rules={[{ required: true, message: 'Введите код подтверждения' }]}
                >
                    <Input label='Смс-код' name='code' placeholder='Код подверждения' clearable />
                </Form.Item>

            </Form>
        </div>
    </>
}