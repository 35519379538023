import React, {useState} from 'react'
import {
    Form,
    Input,
    Button,
    Toast,
} from 'antd-mobile'
import axios from "axios";
import { useNavigate } from 'react-router-dom';

export function Login() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);

        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/login`, {phone: values.phone})
            .then(function (response) {
                setLoading(false);
                navigate("/validate", {state: {phone: values.phone}})
                Toast.show({
                    icon: 'success',
                    content: 'Код был выслан на указанный номер телефона',
                })
            })
            .catch(function (error) {
                setLoading(false);

                if (error.response && error.response.data && error.response.data.detail) {
                    Toast.show({
                        icon: 'fail',
                        content: error.response.data.detail,
                    })
                } else {
                    Toast.show({
                        icon: 'fail',
                        content: "Произошла ошибка",
                    })
                }
            });
    }

    return (
        <>
            <div>
                <h1 className="text-center">Войти</h1>
            </div>
            <div
                title='Получить смс-код'
                padding='0'
                border='none'
                background='transparent'
            >
                <Form
                    layout='horizontal'
                    onFinish={onFinish}
                    footer={
                        <Button block type='submit' color='primary'>
                            Получить смс-код
                        </Button>
                    }
                >
                    <Form.Item
                        name='phone'
                        label='Телефон'
                        rules={[{ required: true, message: 'Введите телефон' }]}
                    >
                        <Input label='Телефон' name='phone' placeholder='Телефон в 10-ти значном формате' clearable />
                    </Form.Item>

                </Form>
            </div>
        </>
    )
}